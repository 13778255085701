<template>
    <div class="star-bill-container">
        <le-card width="100%" class="card">
            <!--   标题   -->
            <div class="card-title" style="margin: 0">数据分析</div>
            <!--   顶栏   -->
            <div class="top-bar">
                <a-select
                        v-model="guild"
                        style="width: 200px"
                        @change="renderGuildData"
                >
                    <a-select-option v-for="(v) in guilds" :key="v.id" :value="v.id">
                        {{ v.name }}
                    </a-select-option>
                </a-select>
                <a-range-picker
                        style="width: 280px"
                        v-model="dateRange"
                        @change="handleDatePickerChange"
                />
            </div>
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="表单">
                    <a-table
                            :columns="columns"
                            :loading="loading"
                            :data-source="dataSource"
                            :pagination="pageOptions"
                            @change="handleTableChange"
                    >
                        <template #op="_,row">
                            <a @click="showDetailsDialog(row.uid,row.broker)">详细数据</a>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="2" tab="图表" force-render>
                    <div id="graphNode" :style="{width: '100%',height: (100*(this.dataSource.length || 1))+'px'}"/>
                </a-tab-pane>
            </a-tabs>
        </le-card>

        <DetailsDialog
                v-model="visible.details"
                :bili_id="uid"
                :name="name"
        />
    </div>
</template>

<script>
import {MixinList} from "@/common/v2/mixin";
import * as echarts from 'echarts';
import DetailsDialog from "@/components/anchor/analysis/DetailsDialog.vue";
import moment from 'moment';

const columns = [
    {
        title: '经纪人',
        dataIndex: 'broker',
        key: 'broker',
        align: 'center',
    },
    {
        title: '查询次数',
        dataIndex: 'query',
        key: 'query',
        align: 'center',
    },
    {
        title: '更新次数',
        dataIndex: 'update',
        key: 'update',
        align: 'center',
    },
    {
        title: '直播间次数',
        dataIndex: 'live',
        key: 'live',
        align: 'center',
    },
    {
        title: '直播更新次数',
        dataIndex: 'live_update',
        key: 'live_update',
        align: 'center',
    },
    {
        title: '操作',
        dataIndex: 'op',
        key: 'op',
        align: 'center',
        scopedSlots: {customRender: 'op'}
    },
];

const nameMapper = {};
columns.forEach(v => nameMapper[v.key] = v.title);

const mixinList = new MixinList(
    function (data) {
        return data.map((item => ({
            uid: item.uid,
            broker: item.uname,
            query: item.search_num,
            update: item.update_num,
            live: item.go_studio_num,
            live_update: item.total_num,
        })));
    }
);

function rand(max) {
    return Math.floor(Math.random() * (max + 1));
}

export default {
    name: "Index",
    components: {DetailsDialog},
    mixins: [mixinList],
    data() {
        return {
            dataUrl: '/v2/operationLog/anchorList',
            isGet: false,
            columns,
            graphHandler: null,
            visible: {
                details: false,
            },
            guilds: [],
            guild: '',
            dateRange: [moment(),moment()],
            uid: 0,
            name: '',
        }
    },
    methods: {
        async renderGuilds() {
            let resp = await this.$api.get('/v2/guildManage/all');
            let {code, data, message} = resp;
            if (code === 200) {
                data.shift();
                this.guilds = data;
                if (this.guilds.length) this.guild = data[0].id;
            } else {
                this.$message.error(message);
            }
        },
        renderGuildData() {
            this.queryParam.guild_manage_id = this.guild;
            this.getData();
        },
        handleDatePickerChange() {
            this.queryParam.start_at = this.dateRange[0]?.format('YYYY-MM-DD 00:00:00') ?? undefined;
            this.queryParam.end_at = this.dateRange[1]?.format('YYYY-MM-DD 23:59:59') ?? undefined;
            this.renderGuildData();
        },
        showDetailsDialog(uid, name) {
            this.uid = uid;
            this.name = name;
            this.visible.details = true;
        }
    },
    async mounted() {
        await this.renderGuilds();
        this.handleDatePickerChange();
    },
    updated() {
        if (this.graphHandler) this.graphHandler.resize();
    },
    watch: {
        dataSource(val) {
            if (!Array.isArray(val)) return;
            if (!this.graphHandler) {
                let node = document.getElementById('graphNode');
                if (!node) return;
                this.graphHandler = echarts.init(node);
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                legend: {
                    bottom: '0',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                },
                yAxis: {
                    type: 'category',
                    data: val.map(data => data.broker),
                    inverse: true,
                },
                series: ['query', 'update', 'live', 'live_update'].map(key => (
                    {
                        name: nameMapper[key],
                        type: 'bar',
                        data: val.map(data => data[key]),
                        barGap: '0',
                        barCategoryGap: '40%',
                    })),
            }
            this.graphHandler.setOption(option);
        }
    }
}
</script>

<style lang="less" scoped>
.card {
  padding: 0 30px 18px;

  min-width: 1100px;

  > *:not(.card-title) {
    margin-top: 12px;
  }

  .top-bar {
    display: flex;

    > *:nth-child(n+2) {
      margin-left: 17px;
    }
  }
}
</style>
